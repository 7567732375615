import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "./body.css";
import "antd/dist/antd.css";
import { ApolloProvider } from "@apollo/react-hooks";
import { Layout } from "antd";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import client from "./graphql/client";
import Header from "./components/Header";
import WebSocketTransport from '@cubejs-client/ws-transport';
// const API_URL = "http://95.216.227.58:31446";
//const Auth_API = "https://auth.tart.io/auth"
// const API_URL = "http://localhost:4000";

var jwt =null 

const queryString = require('query-string');

// console.log(window.location.search);


const parsed = queryString.parse(window.location.search);
// vconsole.log("token is : " , parsed.token)
console.log("token is : " , parsed.token);

//=> {foo: 'bar'}

console.log(window.location.hash);


const cubejsApi = cubejs({
      transport: new WebSocketTransport({
        //authorization: parsed.token,
	    authorization: parsed.token,
        apiUrl: 'wss://aqua-cubejs.tarf.io/' 

        // apiUrl: 'ws://localhost:4000/' 
      })
});


const AppLayout = ({ children }) => (
  <Layout
    style={{
      height: "100%"
    }}
  >
    <Header />
    <Layout.Content>{children}</Layout.Content>
  </Layout>
);

const App = ({ children }) => (
  <CubeProvider cubejsApi={cubejsApi}>
    <ApolloProvider client={client}>
      <AppLayout>{children}</AppLayout>
    </ApolloProvider>
  </CubeProvider>
);

export default App;
